import * as React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Map from '../components/homepage/Map'
import ContactAnimation from '../components/ContactAnimation'
import HomeNewArrivalSlider from '../components/sliders/NewArrivalSlider'
import StillHaveQuestions from '../components/StillHaveQuestions'

const ThankYouEmailSubscribe = () => {
  return (
    <Layout className={'mx-auto w-full'} breadcrumbs={false}>
      <Seo title="THANK YOU" noindex={true} />
      <section className="w-10/12 mx-auto max-w-[500px] my-10">
        <h1 className="text-4xl text-gray-600 font-bold text-center uppercase">
          YOU've been subscribed!
        </h1>
        <p className="text-lg text-center">
          A $200 gift has been sent to your inbox! Be sure to check your email for confirmation.
        </p>
        <ContactAnimation />
      </section>
      <div className="w-10/12 mx-auto">
        <hr />
        <HomeNewArrivalSlider />
        <hr />
        <StillHaveQuestions />
      </div>
      <section className={'mt-16'}>
        <Map />
      </section>
    </Layout>
  )
}

export default ThankYouEmailSubscribe
